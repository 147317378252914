var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "end-of-stage-slide" } },
    [
      _c("h3", {
        staticClass: "header-text mb-4",
        domProps: { innerHTML: _vm._s(_vm.heading) },
      }),
      _c(
        "ct-card-deck",
        [
          !_vm.currentStageSlidesComplete && !_vm.ctaStage
            ? _c(
                "ct-card",
                { on: { click: _vm.goToFirstIncompleteSlideOfCurrentStage } },
                [
                  _c("div", { staticClass: "card__image" }, [
                    _c("picture", [
                      _c("source", {
                        attrs: {
                          srcset: require("@images/illustrations/stageline/stageline-incomplete-steps.webp"),
                          type: "image/webp",
                        },
                      }),
                      _c("img", {
                        staticClass: "image",
                        attrs: {
                          src: require("@images/illustrations/stageline/stageline-incomplete-steps.png"),
                          alt: "woman on staircase",
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "card__text" }, [
                    _vm._v(
                      "\n        Go back and complete uncompleted steps.\n      "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.ctaStage
            ? _c("ct-card", { on: { click: _vm.completeStageAndContinue } }, [
                _c("div", { staticClass: "card__image" }, [
                  _c("picture", [
                    _c("source", {
                      attrs: {
                        srcset: require("@images/illustrations/stageline/stageline-continue-sign.webp"),
                        type: "image/webp",
                      },
                    }),
                    _c("img", {
                      staticClass: "image",
                      attrs: {
                        src: require("@images/illustrations/stageline/stageline-continue-sign.png"),
                        alt: "Continue sign",
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "card__text" }, [
                  _vm._v(
                    "\n        I'm ready to take my business to the next stage.\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "ct-card",
            {
              on: {
                click: function ($event) {
                  _vm.$router.push({ name: "root" }).catch(() => {})
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card__image" },
                [
                  _c("corporation-svg", {
                    staticClass: "card__image",
                    attrs: { alt: "buildings" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "card__text" }, [
                _vm._v(
                  "\n        I would like to manage another business.\n      "
                ),
              ]),
            ]
          ),
          _c("feedback-tool", {
            staticClass: "feedback-tool",
            attrs: { "source-category": _vm.sourceCategory },
          }),
        ],
        1
      ),
      !_vm.ctaStage
        ? _c(
            "b-button",
            {
              staticClass: "back-button",
              attrs: { variant: "default", "aria-label": "previous button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("back")
                },
              },
            },
            [
              _c("fa-icon", {
                staticClass: "fa-xs",
                attrs: { icon: "chevron-left" },
              }),
              _vm._v(" \n    " + _vm._s("Previous") + "\n  "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }